import request from "../../utils/request";

export function getProgressList(query) {
  return request({
    url: '/api/ele/knowledge/progress/list',
    method: 'get',
    params: query
  });
}

// 删除知识数据库
export function deleteProgress(id) {
  return request({
    url: '/api/ele/knowledge/progress/delete/' + id,
    method: 'delete',
  });
}

