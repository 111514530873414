var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container"},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',{staticClass:"table-search"},[_c('a-form-model',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearch),expression:"showSearch"}],ref:"queryForm",attrs:{"model":_vm.queryParams,"size":"small","layout":"inline","label-width":"40px"}},[_c('a-form-model-item',{attrs:{"label":"状态","prop":"status"}},[_c('a-select',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请选择状态","allowClear":""},model:{value:(_vm.queryParams.status),callback:function ($$v) {_vm.$set(_vm.queryParams, "status", $$v)},expression:"queryParams.status"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("等待中")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("进行中")]),_c('a-select-option',{attrs:{"value":"3"}},[_vm._v("已完成")]),_c('a-select-option',{attrs:{"value":"4"}},[_vm._v("检索失败")])],1)],1),_c('a-form-model-item',[_c('a-button',{attrs:{"icon":"search","type":"primary"},on:{"click":_vm.handleQuery}},[_vm._v("查询")]),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"icon":"a-icon-refresh"},on:{"click":_vm.resetQuery}},[_vm._v("重置")])],1),_c('a-form-model-item',{staticStyle:{"float":"right","margin-right":"0px"}},[_c('a-button',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['imagesearchprogress_delete']),expression:"['imagesearchprogress_delete']"}],staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","icon":"delete","disabled":_vm.multiple},on:{"click":_vm.handleDelete}},[_vm._v("删除")])],1)],1)],1),_c('div',{staticClass:"card",staticStyle:{"padding":"6px","height":"calc(100vh - 160px)"}},[_c('a-table',{ref:"table",attrs:{"rowKey":"id","columns":_vm.columns,"dataSource":_vm.list,"row-selection":_vm.rowSelection,"pagination":{
      defaultCurrent: _vm.queryParams.pageNum, // 默认当前页数
      defaultPageSize: _vm.queryParams.pageSize, // 默认当前页显示数据的大小
      total: _vm.total, // 总数，必须先有
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['20', '50', '100'],
      showTotal: (total, range) => `每页${_vm.queryParams.pageSize}条，共 ${total} 条`, // 显示总数
      onShowSizeChange: _vm.onPageSizeChange,
      // 改变每页数量时更新显示
      onChange: _vm.onPageSizeChange,
    },"bordered":""},scopedSlots:_vm._u([{key:"type",fn:function(text){return [(text === '0')?_c('span',[_vm._v("知识库图片")]):_vm._e(),(text === '1')?_c('span',[_vm._v("手动上传检索图片")]):_vm._e()]}},{key:"status",fn:function(text){return [(text === '1')?_c('span',[_vm._v("等待中")]):_vm._e(),(text === '2')?_c('span',[_vm._v("进行中")]):_vm._e(),(text === '3')?_c('span',[_vm._v("已完成")]):_vm._e(),(text === '4')?_c('span',[_vm._v("检索失败")]):_vm._e()]}},{key:"action",fn:function(record){return [(record.status === '3')?_c('a',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['imagesearchprogress_search']),expression:"['imagesearchprogress_search']"}],staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.toImagesearch(record)}}},[_c('a-icon',{attrs:{"type":"chrome"}}),_vm._v(" 图像检索")],1):_vm._e(),_c('a',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['imagesearchprogress_delete']),expression:"['imagesearchprogress_delete']"}],attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.handleDelete(record)}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v(" 删除")],1)]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }